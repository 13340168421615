import logo from './logo.svg';
import './App.css';
import qrCode from './bnb_bridge_qr_code.png'
import Screenshot from './screenshot.png'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img 
          src={'https://secureservercdn.net/104.238.71.140/z9z.56c.myftpupload.com/wp-content/uploads/2020/09/ferrum-logo.png'}
        />
        <div className="App-content">
        <br />
        <h3>Binance Chain to Ethereum Token Bridge</h3>
        <p className="P-mini">
          Use the following instructions to transfer your tokens from Binance chain to
          the Ethereum network. To transfer in the opposite direction please contact 
          <a href="https://t.me/frmtokenbridge"> our telegram support</a>
        </p>
        <br/>
        <span className="P-avg">Fee of <b>150 FRM-DE7</b> will be reduced from the amount.</span>
        <p className="P-avg">
          Send <b>FRM-DE7</b> tokens to the following bridge address on Binance chain.
          <br/>
          <br />
          <span className="P-bold">
          <b>NOTE:</b> ENTER YOUR TARGET ETHEREUM ADDRESS AS <b>MEMO</b>
          </span>
        </p>
        <p className="P-mini">
          Not providing the right <b>memo</b> in your Binance Chain transaction may <br />
          result in loss of your tokens. Memo must be the target eth address starging with 0x
        </p>
        <img
          className="QR-code"
          src={qrCode} />
        <br />
        <a
          className="Address"
        target="_blank"
        href="https://explorer.binance.org/address/bnb1h8dg2vddxgj2qarvs0cxhauk5ucgnftrddlw7d">
          bnb1h8dg2vddxgj2qarvs0cxhauk5ucgnftrddlw7d
        </a>
        <p className="P-mini">
          Please wait a few minutes after your transaction for your tokens to arrive
          in the target address. You can check the target address in etherscan for incoming pending
          transactions.
        </p>

        <br />
        <br />
        <p className="P-mini">
          See the following screenshot as example on how to set the target address in the <b>memo</b>
        </p>
        <img
          className="Screenshot"
          src={Screenshot} />
        <br />
        </div>
      </header>
    </div>
  );
}

export default App;
